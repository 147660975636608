import React, { useMemo } from 'react';
import AppSwitcherDiscoverySectionPlacement from '@post-office-placement/app-switcher-discovery-section/placement';

import { Box, xcss } from '@atlaskit/primitives';

import AtlassianSwitcher, {
	fetchProductRecommendationsWithCollaborators,
	createJoinableSitesProvider,
} from '@atlassian/switcher';
import PostOfficePlacement from '@atlassian/post-office-placement/placement';

import { fg } from '@confluence/feature-gating';
import { useSessionData } from '@confluence/session-data';
import type { FlagsStateContainer } from '@confluence/flags';
import { withFlags } from '@confluence/flags';

import { customShowFlag } from './customShowFlag';

const maxHeightWrapper = xcss({
	maxHeight: 'calc(100vh - 100px)',
});

const joinableProductsInSwitcherProvider = createJoinableSitesProvider(
	fetchProductRecommendationsWithCollaborators('/gateway/api/invitations'),
);

export const AppSwitcherContent = withFlags(({ flags }: { flags: FlagsStateContainer }) => {
	const { cloudId } = useSessionData();
	const customShowFlagMemo = useMemo(() => customShowFlag(flags), [flags]);
	return (
		<Box xcss={maxHeightWrapper}>
			{fg('joinable_products_in_switcher_gate') ? (
				<AtlassianSwitcher
					product="confluence"
					cloudId={cloudId}
					joinableSitesDataProvider={joinableProductsInSwitcherProvider}
					showFlag={customShowFlagMemo}
					discoverySection={
						fg('post_office_app_switcher_discovery_confluence') ? (
							<PostOfficePlacement
								placementId="app-switcher-discovery-section"
								// @ts-ignore - Type 'ComponentType<Partial<ErrorBoundaryPropsWithRender>> is not assignable to type 'PlacementComponent<LocalPlacementProps>'
								PlacementComponent={AppSwitcherDiscoverySectionPlacement}
							/>
						) : undefined
					}
				/>
			) : (
				<AtlassianSwitcher
					product="confluence"
					cloudId={cloudId}
					discoverySection={
						fg('post_office_app_switcher_discovery_confluence') ? (
							<PostOfficePlacement
								placementId="app-switcher-discovery-section"
								// @ts-ignore - Type 'ComponentType<Partial<ErrorBoundaryPropsWithRender>> is not assignable to type 'PlacementComponent<LocalPlacementProps>'
								PlacementComponent={AppSwitcherDiscoverySectionPlacement}
							/>
						) : undefined
					}
				/>
			)}
		</Box>
	);
});
