import React from 'react';

import {
	ConversationAssistantNavBarButton,
	RovoEntitlementSetter,
} from '@confluence/conversation-assistant';
import { AccessStatus, useSessionData } from '@confluence/session-data';
import { fg } from '@confluence/feature-gating';

export const RovoButton = () => {
	const {
		isLoggedIn,
		isRovoEnabled: isRovoEnabledSessionData,
		isRovoLLMEnabled,
		accessStatus,
	} = useSessionData();

	const shouldShowRovo = fg('rovo_use_rovo_llm_enabled_check')
		? isRovoLLMEnabled
		: isRovoEnabledSessionData;

	const isRovoEnabled =
		shouldShowRovo && isLoggedIn && accessStatus !== AccessStatus.EXTERNAL_COLLABORATOR_ACCESS;

	return (
		<>
			{isRovoEnabled && <ConversationAssistantNavBarButton />}
			<RovoEntitlementSetter isRovoEnabled={isRovoEnabled} />
		</>
	);
};
