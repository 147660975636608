import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Popup from '@atlaskit/popup';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Blanket from '@atlaskit/blanket';

import { AppSwitcher } from '@atlassian/navigation-system/top-nav';

import { useRouteDataRef } from '@confluence/route-manager';
import { START_TOUCH } from '@confluence/navdex';
import { AccessStatus, useSessionData } from '@confluence/session-data';

import { AppSwitcherContent } from './AppSwitcherContent';

export const AppSwitcherButton = () => {
	const intl = useIntl();
	const routeDataRef = useRouteDataRef();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { accessStatus } = useSessionData();
	const isLicensed =
		accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
		accessStatus === AccessStatus.LICENSED_USE_ACCESS;

	const [isOpen, setIsOpen] = useState(false);

	const onClick = () => {
		setIsOpen(!isOpen);

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'globalNavigation',
				action: 'clicked',
				actionSubject: 'navigationItem',
				actionSubjectId: 'atlassianSwitcher',
				attributes: {
					selectedItemPageContext: routeDataRef.current?.routeName,
					navigationLayer: 'global',
					navigationContainer: 'top',
					navVersion: '4',
					navdexPointType: START_TOUCH,
				},
			},
		}).fire();
	};

	const onClose = () => {
		setIsOpen(false);
	};

	return isLicensed ? (
		<Popup
			isOpen={isOpen}
			onClose={onClose}
			content={() => <AppSwitcherContent />}
			trigger={(triggerProps) => (
				<div {...triggerProps}>
					<AppSwitcher
						label={intl.formatMessage(i18n.switcher)}
						isSelected={isOpen}
						onClick={onClick}
					/>
					<Blanket isTinted={false} shouldAllowClickThrough={!isOpen} onBlanketClicked={onClose} />
				</div>
			)}
			placement="bottom-start"
			rootBoundary="document"
		/>
	) : null;
};

const i18n = defineMessages({
	switcher: {
		id: 'app-navigation.top-navigation.app-switcher',
		defaultMessage: 'Switch sites or apps',
		description: 'Label for the top navigation app switcher button',
	},
});
